import React from "react";
import { Link } from "react-router-dom";
import s from './index.module.css';

const Footer =  () => {

    return (
    <div className={s.footer}>
        <Link to={'/privacy'}>Privacy Policy</Link>
        <div id="year">© 2023</div>
    </div>);
}

export default Footer;