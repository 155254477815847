import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BattlePrivacy from "./pages/Battle";
import ScrewPrivacy from "./pages/Screw";
import BalloonPrivacy from "./pages/BalloonPrivacy";
import DozerPrivacy from "./pages/Dozer";
import DrillPrivacy from "./pages/Drill";
import ZombiePrivacy from "./pages/Zombie";
import CoinPicker from "./pages/Coin";
import Bullet from "./pages/Bullet";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/battle/privacy",
    element: <BattlePrivacy />,
  },
  {
    path: "/screw/privacy",
    element: <ScrewPrivacy />,
  },
  {
    path: "/balloon/privacy",
    element: <BalloonPrivacy />,
  },
  {
    path: "/dozer/privacy",
    element: <DozerPrivacy />,
  },
  {
    path: "/drill/privacy",
    element: <DrillPrivacy />,
  },
  {
    path: "/zombie/privacy",
    element: <ZombiePrivacy />,
  },
  {
    path: "/coin/privacy",
    element: <CoinPicker />,
  },
  {
    path: "/bullet/privacy",
    element: <Bullet />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
