import React from "react";
import s from './index.module.css';
import { Link } from 'react-router-dom';

const Header =  () => {

    return (
    <div className={s.list}>
        <ul>
            <li>
            <Link to={'/'}>Home</Link>
            </li>
        </ul>
    </div>);
}

export default Header;